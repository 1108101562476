
import jsPDF from "jspdf";
import moment from 'moment';
const logo = require("@/assets/images/labvida.svg");

const docOptions = {
    orientation: "portrait",
    format: 'a4',
    unit: "mm", // 210 x 297
    autoPaging: 'text',
};

var doc = new jsPDF(docOptions);

function _printNormalAndBold(inputValue, startX, Y) {
    const arrayOfNormalAndBoldText = inputValue.split('**');
    arrayOfNormalAndBoldText.map((text, i) => {
        doc.setFont(undefined, 'bold');
        // every even item is a normal font weight item
        if (i % 2 === 0) {
            doc.setFont(undefined, 'normal');
        }
        doc.text(text, startX, Y);
        startX = startX + doc.getTextWidth(text);
    });
}

function printPage(item) {
    doc.setFontSize(10);
    let img = new Image();
    img.src = logo;

    doc.addImage(img, 'jpeg', 0 + 2, 0 + 2, 36, 24)

    let Y = 10;
    doc.setFont(undefined, 'bold').setFontSize(14).text("ORÇAMENTO", 60, Y).setFontSize(10).setFont(undefined, 'normal');
    Y += 10;
    _printNormalAndBold(`Orçamento valido até **${moment().add({ day: 30 }).format("DD/MM/YYYY")}**`, 60, Y);


    Y += 40;
    for (const i of item.exams) {
        Y += 5;
        _printNormalAndBold(`** Exame:** ${i.name} `, 5, Y);
        _printNormalAndBold(`** R$ ${i.price}** `, 150, Y);
    }

    Y += 30;
    doc.setFont(undefined, 'bold').setFontSize(14);
    doc.text(`Valor total: `, 5, Y);
    doc.text(`R$ ${Number(item.totalPrice).toFixed(2)} `, 60, Y);
    doc.text(`Valor com Desconto: `, 5, Y + 10);
    doc.setFontSize(18);
    doc.text(`R$ ${Number(item.totalPrice - item.discount).toFixed(2)} `, 60, Y + 10);
    doc.setFontSize(10).setFont(undefined, 'normal');

    // Y += 5;
    // doc.text("CNPJ/MF: 22.108.794/0001-44", 60, Y);
    // Y += 10;
    // doc.text("SAC: +55 (35) 3473-0824", 60, Y);

    // if (condominium) {
    //     const margimLeft = 5;
    //     if (condominium.name) {
    //         Y += 10;
    //         _printNormalAndBold(`** Nome:** ${ condominium.name } `, margimLeft, Y);
    //     }
    //     if (condominium.cnpj) {
    //         Y += 5;
    //         _printNormalAndBold(`** CNPJ:** ${ condominium.cnpj } `, margimLeft, Y);
    //     }
    //     if (condominium.address) {
    //         Y += 5;
    //         _printNormalAndBold(`** Endereço:** ${ condominium.address } `, margimLeft, Y);
    //     }
    //     if (condominium.cep) {
    //         Y += 5;
    //         _printNormalAndBold(`** CEP:** ${ condominium.cep } `, margimLeft, Y);
    //     }
    //     if (condominium.city) {
    //         Y += 5;
    //         _printNormalAndBold(`** Cidade / UF:** ${ condominium.city } `, margimLeft, Y);
    //     }
    // }

    // doc.setFillColor(255, 88, 91);
    // doc.roundedRect(130, 25, 75, 15, 1, 1, 'F')
    // doc.roundedRect(130, 42, 75, 15, 1, 1, 'F')

    // doc.setTextColor(255, 255, 255);
    // doc.setFont(undefined, 'bold');
    // doc.setFontSize(18);

    // if (printRange)
    //     doc.text(printRange, 132.5, 34.5);
    // if (printInterval)
    //     doc.text(printInterval, 155, 51.5);

    // doc.setFont(undefined, 'normal');
    // doc.setTextColor(0, 0, 0);
    // doc.setFontSize(10);


    // autoTable(doc, {
    //     theme: 'grid',
    //     margin: { left: 5, right: 5 },
    //     headStyles: { fillColor: [255, 88, 91], textColor: [255, 255, 255], fontStyle: 'bold', lineWidth: 0.5, lineColor: [255, 255, 255], halign: 'center' },
    //     bodyStyles: { fillColor: [255, 88, 91], textColor: [255, 255, 255], fontStyle: 'normal', lineWidth: 0.5, lineColor: [255, 255, 255] },
    //     alternateRowStyles: { fillColor: [255, 88, 91], textColor: [255, 255, 255], fontStyle: 'normal', lineWidth: 0.5, lineColor: [255, 255, 255] },
    //     // columnStyles: { 0: { halign: 'center', fillColor: [255, 88, 91] } },
    //     startY: 70,
    //     columns: [
    //         { header: 'Bloco', dataKey: 'block' },
    //         { header: 'Apartamento', dataKey: 'apartment' },
    //         { header: 'Consumo', dataKey: 'consumption' },
    //     ],
    //     body: items,
    // })

    // img.src = footer;
    // doc.addImage(img, "jpeg", 0, pageHeight - 125, pageWidth, 125);

    return doc;
}

export default {
    create: (item) => {
        doc = new jsPDF(docOptions);

        doc = printPage(item)

        return doc;
    },
};