<template>
    <div class="text-center">
        <v-dialog v-model="dialog" width="90vw" height="90vh">
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" style="width: 100%" class="elevation-24" dark v-bind="attrs" v-on="on"> Buscar </v-btn>
            </template>
            <v-card>
                <v-card-title class="text-h5 white--text primary dark">
                    <slot v-bind="{ filtered: listWithFilter, items: items ? items : itemsTable }" />
                </v-card-title>
                <v-card-text>
                    <v-container grid-list-md text-xs-center fluid>
                        <v-layout wrap>
                            <v-flex xs12>
                                <v-text-field :label="$t('Filtrar...')" v-model="filterName" clearable />
                            </v-flex>
                        </v-layout>

                        <v-data-table :headers="headers" :items="listWithFilter" class="elevation-2" :items-per-page.sync="itemsPerPage" sort-by.sync="name"
                            @click:row="tableLineClick">
                            <v-progress-linear progress color="blue" indeterminate></v-progress-linear>
                            <template v-slot:item.extra-column="{ item }">
                                <slot name="extra-column" v-bind="item" />
                            </template>

                            <template v-slot:item.more-extra-column="{ item }">
                                <slot name="more-extra-column" v-bind="item" />
                            </template>

                            <template v-slot:item.actions="{ item }">
                                <slot name="more-btn" v-bind="item" />
                                <v-btn class="ma-2" small @click="editItem(item)" v-if="!hideEdit">
                                    <v-icon dark small>edit</v-icon>
                                </v-btn>
                                <v-btn class="ma-2" color="green" dark small @click="addItem(item)" v-if="!hideAdd">
                                    <v-icon dark small>add</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>

                        <slot name="footer"></slot>

                        <v-layout row justify-center v-if="formclass">
                            <v-dialog v-model="showForm" fullscreen persistent hide-overlay transition="dialog-bottom-transition" @keydown.esc="close">
                                <v-card>
                                    <v-toolbar dark color="primary" dense>
                                        <v-btn icon dark @click="close">
                                            <v-icon>close</v-icon>
                                        </v-btn>
                                        <v-toolbar-title>{{ title }}</v-toolbar-title>
                                    </v-toolbar>
                                    <component v-bind:is="componentForm" :itemToEdit="itemToEdit" :lock="lockForm" @save="saveForm"></component>
                                </v-card>
                            </v-dialog>
                        </v-layout>
                    </v-container>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="ma-2" color="green" dark @click="newItem()" v-if="!hideNew">
                        <v-icon dark left>add</v-icon>
                        {{ $t("Criar Novo") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: {
        endpoint: {
            type: String,
            required: true,
        },
        formclass: {
            type: String,
        },
        items: {
            type: Array,
        },
        aditionalColumns: {
            type: [Object, Array, String],
            default: "",
        },
        hideIdColumn: {
            type: Boolean,
            default: true,
        },
        hideNew: {
            type: Boolean,
            default: false,
        },
        hideEdit: {
            type: Boolean,
            default: false,
        },
        hideAdd: {
            type: Boolean,
            default: false,
        },
        itemsPerPage: {
            type: Number,
            default: 10,
        },
        inlineToggle: {
            type: Boolean,
            default: false,
        },
        forceReloadOnChange: {
            type: Boolean,
            default: true,
        },
        keepOpenFormOnSave: {
            type: [Boolean, String],
            default: false,
        },
        filterColumn: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            dialog: false,
            filterName: null,
            headers: [
                { text: "#", value: "id", align: "left", sortable: false },
                { text: "", value: "actions", align: "right", sortable: false },
            ],

            itemsTable: [],

            showForm: false,
            lockForm: false,
            itemToEdit: null,
        };
    },

    computed: {
        componentForm() {
            return () => (this.formclass ? import(`@/pages/${this.formclass}`) : null);
        },
        title() {
            return this.itemToEdit == null ? this.$t("Cadastrando novo") : this.$t("Editando");
        },
        listWithFilter() {
            let ret = this.items ? this.items : this.itemsTable;
            if (this.filterName) {
                let exp = new RegExp(
                    this.filterName
                        .trim()
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, ""),
                    "i"
                );
                ret = ret.filter(
                    (item) =>
                        typeof item === "object" &&
                        item !== null &&
                        exp.test((this.filterColumn == null ? JSON.stringify(Object.values(item)) : item[this.filterColumn]).normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
                );
            }
            return ret;
        },
    },

    methods: {
        tableLineClick(item) {
            if (item && item.id) {
                this.addItem(item);
            }
        },
        close() {
            this.itemToEdit = {};

            this.$nextTick(() => {
                this.showForm = false;
                this.itemToEdit = null;
            });
        },
        addItem(item) {
            this.$emit("onChoose", item);
            this.dialog = false;
            this.filterName = null;
        },
        editItem(item) {
            this.itemToEdit = {};
            this.$http
                .get(`${this.endpoint}/${item.id}`)
                .then((result) => {
                    this.itemToEdit = result;

                    this.$nextTick(() => {
                        this.showForm = true;
                    });
                })
                .catch((error) => {
                    this.$eventHub.$emit("msgError", error.message ? error.message : this.$t("Erro ao buscar dado para edição."));
                });
        },

        toggle(item) {
            this.$http.patch(`${this.endpoint}`, { id: item.id, data: { enable: item.enable } }).catch((error) => {
                item.enable = !item.enable;
                this.$eventHub.$emit("msgError", error.message ? error.message : this.$t("Erro ao habilitar/desabilitar."));
            });
        },

        newItem() {
            this.itemToEdit = {};
            this.$nextTick(() => {
                this.itemToEdit = null;
                this.showForm = true;
            });
        },

        getMessage(res) {
            if (Array.isArray(res)) {
                let messages = res.map((i) => {
                    return i.message;
                });
                return messages;
            } else if (res.message) {
                return res.message;
            }
            return null;
        },

        saveSuccess(res) {
            this.lockForm = false;
            this.$eventHub.$emit("msgSuccess", this.$http.getMessage(res) || this.$t("Dados salvo com sucesso."));
            this.update();
            if (this.keepOpenFormOnSave == false) {
                this.close();
            }
        },

        saveError(err) {
            this.lockForm = false;
            this.$eventHub.$emit("msgError", this.$http.getMessage(err) || this.$t("Erro ao salvar dados."));
        },

        saveForm(item, resolve) {
            this.lockForm = true;
            if (item.id) {
                this.$http
                    .put(this.endpoint, item.id, item)
                    .then((result) => {
                        resolve && resolve(result);
                        this.saveSuccess(result);
                    })
                    .catch((error) => this.saveError(error));
            } else {
                this.$http
                    .post(this.endpoint, item)
                    .then((result) => {
                        resolve && resolve(result);
                        this.saveSuccess(result);
                    })
                    .catch((error) => this.saveError(error));
            }
        },

        update() {
            if (this.forceReloadOnChange)
                if (!this.items) {
                    this.$http
                        .get(`${this.endpoint}List`)
                        .then((result) => (this.itemsTable = result))
                        .catch(() => (this.itemsTable = []));
                } else {
                    this.$emit("needReload");
                }
        },
    },

    mounted() {
        this.update();
    },

    created() {
        this.aditionalColumns
            .slice()
            .reverse()
            .forEach((column) => {
                this.headers.splice(1, 0, column);
            });

        if (this.hideIdColumn) {
            this.headers.shift();
        }
    },
};
</script>