<template>
    <v-container grid-list-md text-xs-center fluid>
        <template>
            <h1>Atendimento ao Cliente</h1>
            <v-divider class="mb-4"></v-divider>
        </template>
        <v-layout wrap>
            <v-flex xs12>
                <v-card dark :color="item.client != null ? (clientValid ? 'primary' : 'error') : 'grey'">
                    <v-container grid-list-md text-xs-center fluid>
                        <v-layout wrap>
                            <v-flex xs1 sm1 md1>
                                <span class="material-icons" v-if="item.client != null"> check_circle </span>
                            </v-flex>

                            <v-flex xs11 sm11 md8>
                                <div class="d-flex flex-no-wrap justify-space-between">
                                    <div>
                                        <v-list-item-title class="headline"> Cliente </v-list-item-title>

                                        <v-list-item-subtitle v-if="item.client == null"> Selecione um cliente </v-list-item-subtitle>
                                        <v-list-item-subtitle v-if="item.client != null"> Nome: {{ item.client.name }} </v-list-item-subtitle>
                                        <v-list-item-subtitle v-if="item.client != null && item.client.birthDate != null">
                                            Nascimento: {{ item.client.birthDate ? $moment(item.client.birthDate).format("DD/MM/YYYY") : "" }}
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle v-if="item.client != null && item.client.motherName != null">
                                            Mãe: {{ item.client.motherName }}
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle v-if="item.client != null && item.client.cpf != null">
                                            CPF: {{ item.client.cpf }}
                                        </v-list-item-subtitle>
                                    </div>
                                </div>
                            </v-flex>

                            <v-flex xs12 sm12 md3>
                                <SimpleSearch formclass="Clients/form" endpoint="clients" :aditionalColumns="headersClient" @onChoose="chooseClient">
                                    <template> Pesquisa de Clientes </template>
                                    <template v-slot:extra-column="item">
                                        <v-chip class="ma-0" outlined color="success" v-if="item.birthDate"> {{ $moment(item.birthDate).format("DD/MM/YYYY") }} </v-chip>
                                    </template>
                                    <template v-slot:more-btn="item">
                                        <v-chip class="ma-0" outlined color="error" v-if="!item.motherName">
                                            <v-avatar left> <v-icon>error</v-icon> </v-avatar>
                                            Necessário atualizar
                                        </v-chip>
                                    </template>
                                </SimpleSearch>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card>
            </v-flex>
            <v-flex xs12>
                <v-card dark :color="item.doctor != null || item.solicittedByClient ? 'primary' : 'grey'">
                    <v-container grid-list-md text-xs-center fluid>
                        <v-layout wrap>
                            <v-flex xs1>
                                <span class="material-icons" v-if="item.doctor != null"> check_circle </span>
                            </v-flex>

                            <v-flex xs11 md8>
                                <div class="d-flex flex-no-wrap justify-space-between">
                                    <div>
                                        <v-list-item-title class="headline" v-if="item.solicittedByClient == false"> Médico </v-list-item-title>
                                        <v-list-item-title class="headline" v-if="item.solicittedByClient == true"> Solicitado pelo paciente </v-list-item-title>

                                        <v-list-item-subtitle v-if="item.doctor == null && item.solicittedByClient == false"> Selecione um médico </v-list-item-subtitle>
                                        <v-list-item-subtitle v-if="item.doctor != null"> Nome: {{ item.doctor.name }} </v-list-item-subtitle>
                                        <v-list-item-subtitle v-if="item.doctor != null"> CRM: {{ item.doctor.crm }} </v-list-item-subtitle>
                                    </div>
                                </div>
                            </v-flex>

                            <v-flex xs12 md3>
                                <SimpleSearch formclass="Doctor/form" endpoint="doctors" :aditionalColumns="headersDoctor" @onChoose="chooseDoctor">
                                    <template> Pesquisa de Médicos </template>
                                </SimpleSearch>
                                <v-switch v-model="item.solicittedByClient" label="Solicitado pelo paciente" class="mx-0 px-0" color="red" inset @change="requestByClient" />
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card>
            </v-flex>

            <v-flex xs12>
                <v-card dark :color="item.exams.length > 0 ? 'primary' : 'grey'">
                    <v-container grid-list-md text-xs-center fluid>
                        <v-layout wrap>
                            <v-flex xs12 sm9>
                                <v-list-item-title class="headline"> Lista de Exames </v-list-item-title>
                            </v-flex>

                            <v-flex xs12 sm3>
                                <SimpleSearch hideNew hideEdit endpoint="exams" :aditionalColumns="headersExam" @onChoose="chooseExam">
                                    <template> Pesquisa de Exame </template>
                                </SimpleSearch>
                            </v-flex>
                            <v-flex xs12>
                                <v-data-table :headers="headersExamIntern" :items="item.exams" :items-per-page="-1" class="elevation-1 grey" hide-default-footer>
                                    <template v-slot:item.price="{ item }">
                                        {{ priceFormatted(item.price) }}
                                    </template>
                                    <template v-slot:item.extra="{ item }">
                                        <div>
                                            <v-btn v-if="item.observation" :color="item.observationImportant ? 'error' : 'grey'" x-small style="width: 50%"
                                                @click="openObservationModal(item)">
                                                Observações
                                            </v-btn>
                                            <v-btn color="error" x-small style="width: 50%" @click="deleteExam(item)">
                                                <v-icon left small> delete </v-icon>
                                                Deletar
                                            </v-btn>
                                        </div>
                                    </template>
                                </v-data-table>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card>
            </v-flex>

            <v-flex xs12 md6>
                <v-text-field v-model="deliveryDate" :label="$t('Data de entrega')" inputmode="numeric" v-mask="'##/##/####'" />
            </v-flex>

            <v-flex xs12 sm8 md4>
                <v-autocomplete v-model="item.store" :items="stores" item-value="id" :label="$t('Unidade')">
                    <template v-slot:item="data">
                        <v-list-item-content>
                            <v-list-item-title v-html="data.item.name"></v-list-item-title>
                            <v-list-item-subtitle v-html="data.item.city"></v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                    <template v-slot:selection="data"> {{ data.item.name }} ({{ data.item.city }}) </template>
                </v-autocomplete>
            </v-flex>
            <v-flex xs12 sm4 md2>
                <v-container fill-height>
                    <v-btn color="info" x-small dark style="width: 100%" @click="createPDF()" v-if="item.exams.length > 0">
                        <v-icon dark left>print</v-icon> Imprimir orçamento
                    </v-btn>
                </v-container>
            </v-flex>

            <v-flex xs12 v-if="addPartner">
                <v-card dark :color="item.partner != null ? 'primary' : 'grey'">
                    <v-container grid-list-md text-xs-center fluid>
                        <v-layout wrap>
                            <v-flex xs1 sm1 md1>
                                <span class="material-icons" v-if="item.partner != null"> check_circle </span>
                            </v-flex>

                            <v-flex xs11 sm11 md8>
                                <div class="d-flex flex-no-wrap justify-space-between">
                                    <div>
                                        <v-list-item-title class="headline"> Convênio </v-list-item-title>

                                        <v-list-item-subtitle v-if="item.partner == null"> Selecione um convênio </v-list-item-subtitle>
                                        <v-list-item-subtitle v-if="item.partner != null"> Nome: {{ item.partner.name }} </v-list-item-subtitle>
                                    </div>
                                </div>
                            </v-flex>

                            <v-flex xs12 sm12 md3>
                                <SimpleSearch hideNew hideEdit endpoint="partners" :aditionalColumns="headersPartner" @onChoose="choosePartner">
                                    <template> Pesquisa de Convênio </template>
                                </SimpleSearch>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card>
            </v-flex>

            <v-flex xs12 sm6>
                <v-card color="primary" dark>
                    <v-card-title class="text-h5"> {{ priceFormatted(total) }} </v-card-title>
                    <v-card-subtitle>
                        <div class="d-flex flex-no-wrap justify-space-between">
                            Valor Total
                            <v-btn x-small v-if="!addPartner" @click="addPartner = true" color="warning">{{ $t("Adicionar convênio") }}</v-btn>
                        </div>
                    </v-card-subtitle>
                </v-card>
            </v-flex>

            <v-flex xs12 sm6>
                <v-card color="primary" dark>
                    <v-card-title class="text-h5"> {{ priceFormatted(totalWithDiscount) }} </v-card-title>
                    <v-card-subtitle>
                        <div class="d-flex flex-no-wrap justify-space-between">
                            Valor com desconto <strong v-if="discountPercent > 0">{{ discountPercent }} %</strong>
                        </div>
                    </v-card-subtitle>
                </v-card>
            </v-flex>

            <v-flex xs12 class="mb-16">
                <v-btn color="error" style="width: 100%" @click="sendAttendant">Finalizar atendimento</v-btn>
            </v-flex>
        </v-layout>
        <div class="text-center">
            <v-dialog v-model="observation.showModal" persistent width="500">
                <v-card>
                    <v-card-title class="text-h5 white--text error"> Informação importante </v-card-title>

                    <v-card-text v-if="observation.item && observation.item.observation">
                        <div class="text-h5 pa-12" v-html="htmlBreaker(observation.item.observation)"></div>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="observation.showModal = false"> Fechar </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </v-container>
</template>

<script>
import { mask } from "vue-the-mask";
import rules from "@/helpers/rules";
import SimpleSearch from "@/components/core/SimpleSearch";
import budgetPDF from "@/pdf/budget";

export default {
    directives: { mask },

    components: { SimpleSearch },

    data() {
        return {
            budgetPDF,
            idEdit: null,
            rules,
            stores: [],
            addPartner: false,

            item: {
                deliveryDate: null,
                solicittedByClient: false,
                store: null,
                client: null,
                doctor: null,
                partner: null,
                exams: [],
            },

            observation: {
                showModal: false,
                item: null,
            },

            headersClient: [
                { text: "Nome", value: "name" },
                { text: "CPF", value: "cpf" },
                // { text: this.$t("Mãe"), value: "motherName", align: "right", sortable: true },
                { text: this.$t("Data de Nascimento"), value: "extra-column", align: "right", sortable: true },
            ],
            headersDoctor: [
                { text: "Nome", value: "name" },
                { text: "CRM", value: "crm" },
            ],
            headersExam: [
                { text: "Nome", value: "name" },
                { text: "Preço", value: "price" },
            ],
            headersPartner: [
                { text: "Nome", value: "name" },
                { text: "Desconto", value: "discount" },
            ],
            headersExamIntern: [
                { text: "Nome", value: "name" },
                { text: "Preço", value: "price" },
                { text: "", value: "extra" },
            ],
        };
    },

    computed: {
        discountPercent() {
            let discountPercent = 0;
            if (this.item.partner != null && this.item.partner.discount > 0) {
                discountPercent = this.item.partner.discount;
            } else if (this.total >= 200) {
                discountPercent = 20;
            } else if (this.total > 0) {
                discountPercent = 10;
            }
            return discountPercent;
        },
        total() {
            return this.item.exams.reduce((sum, i) => Number(sum) + Number(i.price), 0);
        },
        totalWithDiscount() {
            return this.discountPercent > 0 ? parseInt(this.total * ((100 - this.discountPercent) / 100)) : this.total;
        },
        deliveryDate: {
            get: function () {
                return this.item.deliveryDate ? this.$moment(this.item.deliveryDate).format("DD/MM/YYYY") : "";
            },
            set: function (data) {
                if (data && data.length == 10) {
                    this.item.deliveryDate = this.$moment(data, "DD/MM/YYYY");
                }
            },
        },
        clientValid() {
            return this.item && this.item.client && this.item.client.motherName != null && this.item.client.birthDate != null;
        },
    },

    methods: {
        validateExamList() {
            if (this.item.exams.length > 0 && this.item.client) {
                const gender = this.item.client.gender ? this.item.client.gender.toLowerCase() : null;
                if (gender != null && (gender == "feminino" || gender == "masculino")) {
                    let genderOpositive = gender == "feminino" ? "masculino" : "feminino";
                    if (this.item.exams.filter((e) => e.name.toLowerCase().includes(genderOpositive)).length > 0) {
                        this.$eventHub.$emit("msgError", "Temos exames na listagem que não é compatível com o sexo do paciente.");
                    } else {
                        return true;
                    }
                } else {
                    return true; // If not has gender, will be authorize
                }
            }
            return false; // Needs client and exam to validate
        },
        createPDF() {
            let doc = budgetPDF.create({
                totalPrice: this.total,
                discount: this.total - this.totalWithDiscount,
                payedByPartner: this.discountPercent == 100,
                partner: this.item.partner ? this.item.partner.id : null,
                exams: this.item.exams.map((e) => {
                    return { name: e.name, price: e.price };
                }),
            });

            // doc.output("report.pdf");
            window.open(doc.output("bloburl"));
        },
        deleteExam(item) {
            this.item.exams = this.item.exams.filter((e) => e.id != item.id);
        },
        htmlBreaker(text) {
            return text.replaceAll("\n", "<br/>");
        },
        chooseClient(item) {
            console.log(item);
            this.item.client = item;
            this.validateExamList();
        },
        chooseDoctor(item) {
            console.log(item);
            this.item.doctor = item;
            this.item.solicittedByClient = false;
        },
        choosePartner(item) {
            console.log(item);
            this.item.partner = item;
        },
        openObservationModal(item) {
            this.observation.item = item;
            this.observation.showModal = true;
        },
        chooseExam(item) {
            console.log(item);
            let found = this.item.exams.filter((e) => e.id == item.id).length > 0;

            if (found) {
                this.$eventHub.$emit("msgError", "Exame já está na lista.");
            } else {
                this.item.exams.push(item);
                if (item.observationImportant == true) {
                    this.openObservationModal(item);
                }
            }
            this.validateExamList();
        },
        requestByClient(status) {
            if (status == true) {
                this.item.doctor = null;
            }
        },
        priceFormatted(price) {
            return `R$ ${Number(price).toFixed(2)}`;
        },
        sendAttendant() {
            if (this.item.store == null) {
                this.$eventHub.$emit("msgError", "Selecione de qual unidade está sendo feito o atendimento");
            } else if (this.item.client == null) {
                this.$eventHub.$emit("msgError", "Selecione o cliente");
            } else if (!this.clientValid) {
                this.$eventHub.$emit("msgError", "Atualize o cadastro do cliente");
            } else if (this.item.doctor == null && this.item.solicittedByClient == false) {
                this.$eventHub.$emit("msgError", "Selecione o médico");
            } else if (this.item.exams.length == 0) {
                this.$eventHub.$emit("msgError", "Selecione pelo menos um exame");
            } else if (this.item.deliveryDate == null) {
                this.$eventHub.$emit("msgError", "Coloque uma data de entrega");
            } else if (this.validateExamList() == true) {
                let test = this.rules.rangeDate("DD/MM/YYYY", this.$moment(), this.$moment().add({ month: 1 }))(this.item.deliveryDate);
                if (test == true) {
                    let itemToSend = {
                        totalPrice: this.total,
                        discount: this.total - this.totalWithDiscount,
                        payedByPartner: this.discountPercent == 100,
                        deliveryDate: this.item.deliveryDate,
                        solicittedByClient: this.item.solicittedByClient,
                        store: this.item.store,
                        client: this.item.client.id,
                        partner: this.item.partner ? this.item.partner.id : null,
                        doctor: this.item.doctor ? this.item.doctor.id : null,
                        exams: this.item.exams.map((e) => {
                            return { ref: e.id, price: e.price };
                        }),
                    };
                    if (this.idEdit) {
                        this.$http
                            .put("service", this.idEdit, itemToSend)
                            .then(() => {
                                this.$eventHub.$emit("msgSuccess", "Atendimento salvo.");
                                this.$router.push({ path: `/payments/${this.idEdit}` });
                            })
                            .catch((error) => {
                                this.$eventHub.$emit("msgError", this.$http.getMessage(error) || this.$t("Erro ao salvar dados."));
                            });
                    } else {
                        this.$http
                            .post("service", itemToSend)
                            .then((response) => {
                                this.$eventHub.$emit("msgSuccess", "Atendimento salvo.");
                                this.$router.push({ path: `/payments/${response.id}` });
                            })
                            .catch((error) => {
                                this.$eventHub.$emit("msgError", this.$http.getMessage(error) || this.$t("Erro ao salvar dados."));
                            });
                    }
                } else {
                    this.$eventHub.$emit("msgError", test);
                }
            }
        },
        checkEdit() {
            if (this.$route.query.id) {
                this.$http.get(`service/${this.$route.query.id}`).then((result) => {
                    this.idEdit = this.$route.query.id;

                    this.item.client = {
                        id: result.client._id,
                        name: result.client.name,
                        motherName: result.client.motherName,
                        birthDate: result.client.birthDate,
                        gender: result.client.gender,
                    };

                    this.item.partner = result.partner;
                    this.deliveryDate = this.$moment(result.deliveryDate).format("DD/MM/YYYY");
                    this.item.solicittedByClient = result.solicittedByClient;

                    if (result.doctor) this.item.doctor = { id: result.doctor._id, name: result.doctor.name, crm: result.doctor.crm };
                    if (result.partner) {
                        this.addPartner = true;
                        this.item.partner = { id: result.partner._id, name: result.partner.name, discount: result.partner.discount };
                    }
                    if (result.store) this.item.store = result.store._id;

                    result.exams.forEach((i) => {
                        this.item.exams.push({ id: i.ref._id, name: i.ref.name, price: i.ref.price });
                    });

                    // this.item = result;
                    // if (this.item.store && this.item.store._id) this.item.store = this.store._id;
                    console.log(this.item);
                });
            }
        },
    },

    mounted() {
        this.$http
            .get(`stores/ByUser`)
            .then((result) => {
                this.stores = result;
                if (this.stores.length == 1) {
                    this.item.store = this.stores[0].id;
                }
                this.checkEdit();
            })
            .catch(() => (this.stores = []));
    },
};
</script>
