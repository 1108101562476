var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"width":"90vw","height":"90vh"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"elevation-24",staticStyle:{"width":"100%"},attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Buscar ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 white--text primary dark"},[_vm._t("default",null,null,{ filtered: _vm.listWithFilter, items: _vm.items ? _vm.items : _vm.itemsTable })],2),_c('v-card-text',[_c('v-container',{attrs:{"grid-list-md":"","text-xs-center":"","fluid":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-text-field',{attrs:{"label":_vm.$t('Filtrar...'),"clearable":""},model:{value:(_vm.filterName),callback:function ($$v) {_vm.filterName=$$v},expression:"filterName"}})],1)],1),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.listWithFilter,"items-per-page":_vm.itemsPerPage,"sort-by.sync":"name"},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"click:row":_vm.tableLineClick},scopedSlots:_vm._u([{key:"item.extra-column",fn:function(ref){
var item = ref.item;
return [_vm._t("extra-column",null,null,item)]}},{key:"item.more-extra-column",fn:function(ref){
var item = ref.item;
return [_vm._t("more-extra-column",null,null,item)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_vm._t("more-btn",null,null,item),(!_vm.hideEdit)?_c('v-btn',{staticClass:"ma-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',{attrs:{"dark":"","small":""}},[_vm._v("edit")])],1):_vm._e(),(!_vm.hideAdd)?_c('v-btn',{staticClass:"ma-2",attrs:{"color":"green","dark":"","small":""},on:{"click":function($event){return _vm.addItem(item)}}},[_c('v-icon',{attrs:{"dark":"","small":""}},[_vm._v("add")])],1):_vm._e()]}}],null,true)},[_c('v-progress-linear',{attrs:{"progress":"","color":"blue","indeterminate":""}})],1),_vm._t("footer"),(_vm.formclass)?_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-dialog',{attrs:{"fullscreen":"","persistent":"","hide-overlay":"","transition":"dialog-bottom-transition"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close.apply(null, arguments)}},model:{value:(_vm.showForm),callback:function ($$v) {_vm.showForm=$$v},expression:"showForm"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary","dense":""}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("close")])],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.title))])],1),_c(_vm.componentForm,{tag:"component",attrs:{"itemToEdit":_vm.itemToEdit,"lock":_vm.lockForm},on:{"save":_vm.saveForm}})],1)],1)],1):_vm._e()],2)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),(!_vm.hideNew)?_c('v-btn',{staticClass:"ma-2",attrs:{"color":"green","dark":""},on:{"click":function($event){return _vm.newItem()}}},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v("add")]),_vm._v(" "+_vm._s(_vm.$t("Criar Novo"))+" ")],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }